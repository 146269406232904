@font-face {
  font-family: 'HouschkaRounded';
  src: url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_LightRegular.woff2')
      format('woff2'),
    url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_LightRegular.woff')
      format('woff'),
    url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_LightRegular.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HouschkaRounded';
  src: url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_MediumRegular.woff2')
      format('woff2'),
    url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_MediumRegular.woff')
      format('woff'),
    url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_MediumRegular.otf');
  font-weight: 500;
  font-style: medium;
  font-display: swap;
}

@font-face {
  font-family: 'HouschkaRounded';
  src: url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_DemiBoldRegular.woff2')
      format('woff2'),
    url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_DemiBoldRegular.woff')
      format('woff'),
    url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_DemiBoldRegular.otf');
  font-weight: 600;
  font-style: semibold;
  font-display: swap;
}

@font-face {
  font-family: 'HouschkaRounded';
  src: url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_BoldRegular.woff2')
      format('woff2'),
    url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_BoldRegular.woff')
      format('woff'),
    url('https://cdn.prescriberpoint.com/fonts/HouschkaRounded_BoldRegular.otf');
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'HouschkaPro';
  src: url('https://cdn.prescriberpoint.com/fonts/HouschkaPro_LightRegular.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HouschkaPro';
  src: url('https://cdn.prescriberpoint.com/fonts/HouschkaPro_MediumRegular.otf');
  font-weight: 500;
  font-style: medium;
  font-display: swap;
}

@font-face {
  font-family: 'HouschkaPro';
  src: url('https://cdn.prescriberpoint.com/fonts/HouschkaPro_DemiBoldRegular.otf');
  font-weight: 600;
  font-style: semibold;
  font-display: swap;
}

@font-face {
  font-family: 'HouschkaPro';
  src: url('https://cdn.prescriberpoint.com/fonts/HouschkaPro_BoldRegular.otf');
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}
